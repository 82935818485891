import React from 'react';
import { navigateTo } from 'gatsby-link';
import { StaticQuery, graphql } from 'gatsby';
import Layout from 'components/layout';
import Section from 'containers/section';
import Column from 'containers/column';
import Form from 'components/voucherForm';
import Title from 'components/title';
import { theme } from 'constants/theme';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import Image from 'components/image';

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export default class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigateTo(form.getAttribute('action')))
      .catch(error => alert(error));
  };

  render() {
    return (
      <StaticQuery
        query={graphql`
          query VouchersQuery {
            giftVouchersJson {
              title
              heroImage {
                image {
                  childImageSharp {
                    fluid(maxWidth: 1500, quality: 80) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                title
              }
            }
          }
        `}
        render={data => (
          <Layout headerTheme="dark">
            <Section padding="50px 5vw 0">
              <Column>
                <Image
                  src={data.giftVouchersJson.heroImage.image}
                  alt={data.giftVouchersJson.heroImage.title}
                />
              </Column>
              <Column>
                <Title as="h1" color={theme.colors.base} size="large">
                  {data.giftVouchersJson.title}
                </Title>
                <Form />
              </Column>
            </Section>
          </Layout>
        )}
      />
    );
  }
}
