import React from 'react';
//import { DateRangePicker } from 'react-dates';
//import 'react-dates/initialize';
//import 'react-dates/lib/css/_datepicker.css';
import { navigateTo } from 'gatsby-link';
import { Container } from '../form/form.css';

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export default class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigateTo(form.getAttribute('action')))
      .catch(error => alert(error));
  };

  render() {
    return (
      <Container
        name="Voucher Enquiry"
        method="post"
        action="/thanks/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={this.handleSubmit}
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="Voucher Enquiry" />
        <p hidden>
          <label htmlFor="bot-field">
            Don’t fill this out:{' '}
            <input name="bot-field" onChange={this.handleChange} />
          </label>
        </p>

        <p className="half">
          <label htmlFor="First Name">
            First name
            <br />
            <input
              type="text"
              name="First Name"
              onChange={this.handleChange}
              required
            />
          </label>
        </p>
        <p className="half">
          <label htmlFor="Last Name">
            Last name
            <br />
            <input
              type="text"
              name="Last Name"
              onChange={this.handleChange}
              required
            />
          </label>
        </p>
        <p className="half">
          <label htmlFor="email">
            Email
            <br />
            <input
              type="email"
              name="email"
              onChange={this.handleChange}
              required
            />
          </label>
        </p>
        <p className="half">
          <label htmlFor="phone">
            Phone
            <br />
            <input
              type="phone"
              name="phone"
              onChange={this.handleChange}
              required
            />
          </label>
        </p>
        <p className="half">
          <label htmlFor="Gift Recipient">
            Gift Recipient Name
            <br />
            <input
              type="text"
              name="Gift Recipient"
              onChange={this.handleChange}
              required
            />
          </label>
        </p>
        <p className="half">
          <label htmlFor="Voucher Value">
            Voucher Value ($AUD)
            <br />
            <input
              type="number"
              name="Voucher Value"
              min="1"
              step="any"
              onChange={this.handleChange}
              required
            />
          </label>
        </p>
        <p className="radio-container">
          <input
            type="radio"
            name="Delivery"
            value="digital"
            onChange={this.handleChange}
            required
          />
          <label htmlFor="digital">Digital</label>
          <input
            type="radio"
            name="Delivery"
            value="physical"
            onChange={this.handleChange}
            required
          />
          <label htmlFor="physical">Physical</label>
        </p>
        <p>
          <label htmlFor="message">
            Message:
            <br />
            <textarea name="message" onChange={this.handleChange} required />
          </label>
        </p>
        <p>
          <button className="submit" type="submit">
            Order
          </button>
        </p>
      </Container>
    );
  }
}
